import React, { useState, ReactElement, useEffect, useRef } from 'react';
import { ContentContainer, TabWrap, TabContentWrap, TabTitle } from './ContentStyle';
import { DialogTitle } from 'components/Dialog';
import { getChannelList } from 'api/integrationStep';
import { useAppStore } from 'stores/appStore';
import { useLocation, useNavigate } from 'react-router-dom';

import PrimaryDialog from './PrimaryDialog';
import BukalapakFullIcon from 'assets/img/bukalapak-full.png';
import AkulakuFullIcon from 'assets/img/akulaku-full.png';
import BlibliFullIcon from 'assets/img/blibli-full.png';
import LazadaFullIcon from 'assets/img/lazada-full.png';
import ShopeeFullIcon from 'assets/img/shopee-full.png';
import TokopediaFullIcon from 'assets/img/tokopedia-full.png';
import ZaloraFullIcon from 'assets/img/zalora.png';
import TadaFullIcon from 'assets/img/tada-full.png';
import DealPosFullIcon from 'assets/img/dealpos-full.png';
import EvermosFullIcon from 'assets/img/evermos-full.png';
import TiktokFullIcon from 'assets/img/tiktok-full.png';
import FacebookFullIcon from 'assets/img/facebook-full.png';
import JubelioPosFullIcon from 'assets/img/jubeliopos-full.png';
import ShopifyFullIcon from 'assets/img/shopify-full.png';
import WoocommerceFullIcon from 'assets/img/woocommerce-full.png';
import ShoplineIcon from 'assets/img/shopline.png';
import PlugoFullIcon from 'assets/img/plugo-full.png';
import ShopeeContent from './MarketPlacesDialog/Shopee/DialogContent';
import TokopediaContent from './MarketPlacesDialog/Tokopedia/DialogContent';
import BukalapakContent from './MarketPlacesDialog/Bukalapak/DialogContent';
import LazadaContent from './MarketPlacesDialog/Lazada/DialogContent';
import BlibliContent from './MarketPlacesDialog/Blibli/DialogContent';
import ZaloraContent from './MarketPlacesDialog/Zalora/DialogContent';
import AkulakuContent from './MarketPlacesDialog/Akulaku/DialogContent';
import TADAContent from './MarketPlacesDialog/TADA/DialogContent';
import EvermosContent from './SocialCommerceDialog/Evermos/DialogContent';
import TiktokContent from './SocialCommerceDialog/Tiktok/DialogContent';
import FacebookContent from './SocialCommerceDialog/Facebook/DialogContent';
import JubelioPOS from './PointofSalesDialog/JubelioPOS/DialogContent';
import DealPOSContent from './PointofSalesDialog/DealPOS/DialogContent';
import WooCommerceContent from './WebstoreDialog/WooCommerce/DialogContent';
import ShopifyContent from './WebstoreDialog/Shopify/DialogContent';
import ShoplineContent from './WebstoreDialog/Shopline/DialogContent';
import PlugoContent from './WebstoreDialog/Plugo/DialgContent';
import Accordion from './Accordion';
import { showAlert } from 'components/Alert';

export interface ChannelType {
  channel_name: string;
  channel_id: number;
  image: string;
  titleElement: ReactElement;
  content: ReactElement;
  logoSize: { width: string; height: string };
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const tabList = [
  {
    id: 'market_place',
    title: 'Marketplace'
  },
  {
    id: 'social_commerce',
    title: 'Social Commerce'
  },
  {
    id: 'pos',
    title: 'Point Of Sales'
  },
  {
    id: 'webstore',
    title: 'Webstore'
  }
];

const Contents: React.FunctionComponent = () => {
  const params = useQuery();
  const navigate = useNavigate();
  const { canSkip, cantSkip, setIndexAccordion, disablePrevButton } = useAppStore();
  const [activeTab, setActiveTab] = useState(tabList[0].id);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<ReactElement>();
  const [content, setContent] = useState<ReactElement>();
  const [channelList, setChannelList] = useState([]);

  const isMounted = useRef(true);

  const handleShowPrimary = (el: ChannelType, idx) => {
    if (el) {
      setOpen(true);
      if (!disablePrevButton) {
        setIndexAccordion(idx);
      }
      setTitle(el.titleElement);
      setContent(el.content);
    }
  };
  const handleClosePrimary = () => setOpen(false);
  const getListChannel = async () => {
    try {
      const { data, status, statusText } = await getChannelList();
      if (status === 200) {
        setChannelList(data);
      } else {
        showAlert('error', `Something went wrong ${statusText}`, 5000);
      }
    } catch (err) {
      const error = err as Error;
      console.error(error);
    }
  };

  const marketPlace = [
    {
      channel_name: 'shopee',
      channel_id: 64,
      image: ShopeeFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '122px', height: '37px' }} image={ShopeeFullIcon} altName="shopee" />
      ),
      content: <ShopeeContent />,
      logoSize: { width: '70px', height: 'auto' }
    },
    {
      channel_name: 'tokopedia',
      channel_id: 128,
      image: TokopediaFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '132px', height: '33px' }} image={TokopediaFullIcon} altName="tokopedia" />
      ),
      content: <TokopediaContent handleClosePrimary={handleClosePrimary} getListChannel={getListChannel} />,
      logoSize: { width: '85px', height: 'auto' }
    },
    {
      channel_name: 'bukalapak',
      channel_id: 2,
      image: BukalapakFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '136px', height: '34px' }} image={BukalapakFullIcon} altName="bukalapak" />
      ),
      content: <BukalapakContent />,
      logoSize: { width: '85px', height: 'auto' }
    },
    {
      channel_name: 'lazada',
      channel_id: 4,
      image: LazadaFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '112px', height: '26px' }} image={LazadaFullIcon} altName="lazada" />
      ),
      content: <LazadaContent />,
      logoSize: { width: '75px', height: 'auto' }
    },
    {
      channel_name: 'blibli',
      channel_id: 32,
      image: BlibliFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '66px', height: '26px' }} image={BlibliFullIcon} altName="blibli" />
      ),
      content: <BlibliContent handleClosePrimary={handleClosePrimary} getListChannel={getListChannel} />,
      logoSize: { width: '60px', height: 'auto' }
    },
    {
      channel_name: 'zalora',
      channel_id: 8,
      image: ZaloraFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '84px', height: '18px' }} image={ZaloraFullIcon} altName="zalora" />
      ),
      content: <ZaloraContent handleClosePrimary={handleClosePrimary} getListChannel={getListChannel} />,
      logoSize: { width: '70px', height: 'auto' }
    },
    {
      channel_name: 'akulaku',
      channel_id: 65536,
      image: AkulakuFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '103px', height: '23px' }} image={AkulakuFullIcon} altName="akulaku" />
      ),
      content: <AkulakuContent />,
      logoSize: { width: '75px', height: 'auto' }
    }
  ];
  const socialCommerce = [
    {
      channel_name: 'tada',
      channel_id: 131077,
      image: TadaFullIcon,
      titleElement: <DialogTitle logoSize={{ width: '66px', height: '21px' }} image={TadaFullIcon} altName="tada" />,
      content: <TADAContent handleClosePrimary={handleClosePrimary} getListChannel={getListChannel} />,
      logoSize: { width: '45px', height: 'auto' }
    },
    {
      channel_name: 'evermos',
      channel_id: 131078,
      image: EvermosFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '45px', height: '22px' }} image={EvermosFullIcon} altName="evermos" />
      ),
      content: <EvermosContent handleClosePrimary={handleClosePrimary} getListChannel={getListChannel} />,
      logoSize: { width: '75px', height: 'auto' }
    },
    {
      channel_name: 'facebook',
      channel_id: 16386,
      image: FacebookFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '92px', height: '17px' }} image={FacebookFullIcon} altName="facebook" />
      ),
      content: <FacebookContent />,
      logoSize: { width: '75px', height: 'auto' }
    },
    {
      channel_name: 'tiktok',
      channel_id: 131076,
      image: TiktokFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '89px', height: '26px' }} image={TiktokFullIcon} altName="tiktok" />
      ),
      content: <TiktokContent />,
      logoSize: { width: '60px', height: 'auto' }
    }
  ];
  const pos = [
    {
      channel_name: 'jubeliopos',
      channel_id: 524288,
      image: JubelioPosFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '122px', height: '37px' }} image={JubelioPosFullIcon} altName="jubeliopos" />
      ),
      content: <JubelioPOS handleClosePrimary={handleClosePrimary} getListChannel={getListChannel} />,
      logoSize: { width: '85px', height: 'auto' }
    },
    {
      channel_name: 'dealpos',
      channel_id: 262144,
      image: DealPosFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '90px', height: '27px' }} image={DealPosFullIcon} altName="dealpos" />
      ),
      content: <DealPOSContent />,
      logoSize: { width: '60px', height: 'auto' }
    }
  ];
  const webstore = [
    {
      channel_name: 'woocommerce',
      channel_id: 131072,
      image: WoocommerceFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '125px', height: '34px' }} image={WoocommerceFullIcon} altName="woocommerce" />
      ),
      content: <WooCommerceContent handleClosePrimary={handleClosePrimary} getListChannel={getListChannel} />,
      logoSize: { width: '80px', height: 'auto' }
    },
    {
      channel_name: 'shopify',
      channel_id: 1048576,
      image: ShopifyFullIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '107px', height: '32px' }} image={ShopifyFullIcon} altName="shopify" />
      ),
      content: <ShopifyContent handleClosePrimary={handleClosePrimary} getListChannel={getListChannel} />,
      logoSize: { width: '75px', height: 'auto' }
    },
    {
      channel_name: 'shopline',
      channel_id: 131079,
      image: ShoplineIcon,
      titleElement: (
        <DialogTitle logoSize={{ width: '108px', height: '34px' }} image={ShoplineIcon} altName="shopline" />
      ),
      content: <ShoplineContent />,
      logoSize: { width: '75px', height: 'auto' }
    },
    {
      channel_name: 'plugo',
      channel_id: 131084,
      image: PlugoFullIcon,
      titleElement: <DialogTitle logoSize={{ width: '108px', height: '34px' }} image={PlugoFullIcon} altName="plugo" />,
      content: <PlugoContent handleClosePrimary={handleClosePrimary} getListChannel={getListChannel} />,
      logoSize: { width: '75px', height: 'auto' }
    }
  ];

  useEffect(() => {
    if (channelList.length > 0 && !disablePrevButton) {
      canSkip();
    }
  }, [channelList]);

  useEffect(() => {
    cantSkip();
    if (isMounted.current) {
      const activeChannel = params.get('id');
      const selectedTab = params.get('activeTab');
      if (selectedTab || activeChannel) {
        setIndexAccordion(activeChannel);
        setActiveTab(selectedTab);
        navigate('/');
      }
      getListChannel();
      isMounted.current = false;
    }
  }, []);

  const handleChangeTab = (id) => {
    setIndexAccordion(undefined);
    setActiveTab(id);
  };

  return (
    <ContentContainer>
      <TabWrap>
        {tabList.map((el) => (
          <TabTitle onClick={() => handleChangeTab(el.id)} activeTab={activeTab} tabId={el.id} key={el.id}>
            {el.title}
          </TabTitle>
        ))}
      </TabWrap>
      <TabContentWrap>
        {activeTab === 'market_place' && (
          <Accordion
            data={marketPlace}
            channelList={channelList}
            getListChannel={getListChannel}
            handleShowModal={handleShowPrimary}
          />
        )}
        {activeTab === 'social_commerce' && (
          <Accordion
            data={socialCommerce}
            channelList={channelList}
            getListChannel={getListChannel}
            handleShowModal={handleShowPrimary}
          />
        )}
        {activeTab === 'pos' && (
          <Accordion
            data={pos}
            channelList={channelList}
            getListChannel={getListChannel}
            handleShowModal={handleShowPrimary}
          />
        )}
        {activeTab === 'webstore' && (
          <Accordion
            data={webstore}
            channelList={channelList}
            getListChannel={getListChannel}
            handleShowModal={handleShowPrimary}
          />
        )}
      </TabContentWrap>
      <PrimaryDialog title={title} content={content} open={open} handleClose={handleClosePrimary} />
    </ContentContainer>
  );
};

export default Contents;
