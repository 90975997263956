import { coreAPI } from './axiosClient';

export const woocommerceAuthorize = (host: string, key: string, secret: string, version: string) => {
  return coreAPI({
    method: 'POST',
    url: 'woocommerce/register',
    data: { 
      host, key, secret, version, is_use_custom_order_status: false,
    },
  });
};

export const shopifyAuthorize = (host: string, key: string, secret: string) => {
  return coreAPI({
    method: 'POST',
    url: 'shopify/register',
    data: {
      host, key, secret,
    },
  });
};
// ▪ Store URL: https://codetesting.jubelio.store
// ▪ Consumer Key: ck_1cbb2c1902d56b629cd9a555cc032c4b478b26ce
// ▪ Consumer Secret: cs_7be10f0328c5b1d6a1a3077165b226af71d8b9dc
// ▪ API Docs: https://woocommerce.github.io/woocommerce-rest-api-docs

// ck_bd79f5d85822dab30156b067a0cb0b63f3ba0683
// cs_6eb1a8f77223feb94117c063d376f35e12bb1ed1

export const plugoAuthorize = (vendorId: string, apiKey: string) => {
  return coreAPI({
    method: 'POST',
    url: 'plugo/register',
    data: {
      vendorId, apiKey,
    },
  });
};