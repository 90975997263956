import BukalapakFullIcon from 'assets/img/bukalapak-icon.png';
import AkulakuFullIcon from 'assets/img/akulaku-icon.png';
import BlibliFullIcon from 'assets/img/blibli-icon.png';
import LazadaFullIcon from 'assets/img/lazada-icon.png';
import ShopeeFullIcon from 'assets/img/shopee-icon.png';
import TokopediaFullIcon from 'assets/img/tokopedia-icon.png';
import ZaloraFullIcon from 'assets/img/zalora.png';
import ZilingoFullIcon from 'assets/img/zilingo.png';
import TadaFullIcon from 'assets/img/tada-full.png';
import DealPosFullIcon from 'assets/img/dealpos-full.png';
import EvermosFullIcon from 'assets/img/evermos-icon.png';
import FacebookFullIcon from 'assets/img/facebook-full.png';
import JubelioPosFullIcon from 'assets/img/jubeliopos-full.png';
import ShopifyFullIcon from 'assets/img/shopify-icon.png';
import TiktokFullIcon from 'assets/img/tiktok-icon.png';
import WoocommerceFullIcon from 'assets/img/woocommerce-full.png';
import ShoplineIcon from 'assets/img/shopline.png';
import PlugoFullIcon from 'assets/img/plugo-full.png';
import JubelioIcon from 'assets/img/jubelio-icon.png';
import PlugoIcon from 'assets/img/plugo-icon.png';
import { ChannelDetail } from 'interfaces/Channels';

interface Channels {
  [key: string] : number;
}

const CHANNELS: Channels = {
  SHOPEE: 64,
  TOKOPEDIA: 128,
  BUKALAPAK: 2,
  LAZADA: 4,
  BLIBLI: 32,
  ZALORA: 8,
  ZILINGO: 2048,
  AKULAKU: 65536,
  TADA: 131077,
  JD: 4096,
  EVERMOS: 131078,
  FACEBOOK: 16386,
  TIKTOK: 131076,
  JUBELIO_POS: 524288,
  DEALPOS: 262144,
  WOOCOMMERCE: 131072,
  SHOPIFY: 1048576,
  SHOPLINE: 131079,
  ELEVENIA: 16,
  BLANJA: 512,
  QOO10: 1024,
  LYKE: 8192,
  LINE: 16384,
  INSTAGRAM: 16385,
  PRESTASHOP: 131073,
  MAGENTO: 131074,
  RALALI: 131075,
  JUBELIO_STORE: 524289,
  PLUGO: 131084,
  INTERNAL: 1
};

const channelDetail: ChannelDetail = {
  [CHANNELS.BUKALAPAK]: {
    channelName: 'Bukalapak',
    channelId: CHANNELS.BUKALAPAK,
    channelLogo: BukalapakFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/pengaturan-sistem/integrasi-marketplace/cara-integrasi-bukalapak-ke-jubelio/'
  },
  [CHANNELS.LAZADA]: {
    channelName: 'Lazada',
    channelId: CHANNELS.LAZADA,
    channelLogo: LazadaFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/pengaturan-sistem/integrasi-marketplace/cara-integrasi-lazada-ke-jubelio/'
  },
  [CHANNELS.ZALORA]: {
    channelName: 'Zalora',
    channelId: CHANNELS.ZALORA,
    channelLogo: ZaloraFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/menu-integrasi/integrasi-channel-penjualan-ke-jubelio/cara-integrasi-zalora-ke-jubelio/'
  },
  [CHANNELS.BLIBLI]: {
    channelName: 'Blibli',
    channelId: CHANNELS.BLIBLI,
    channelLogo: BlibliFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/pengaturan-sistem/integrasi-marketplace/cara-integrasi-blibli-ke-jubelio/'
  },
  [CHANNELS.SHOPEE]: {
    channelName: 'Shopee',
    channelId: CHANNELS.SHOPEE,
    channelLogo: ShopeeFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/pengaturan-sistem/integrasi-marketplace/cara-integrasi-shopee-ke-jubelio/'
  },
  [CHANNELS.TOKOPEDIA]: {
    channelName: 'Tokopedia',
    channelId: CHANNELS.TOKOPEDIA,
    channelLogo: TokopediaFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/menu-integrasi/integrasi-channel-penjualan-ke-jubelio/cara-integrasi-tokopedia-ke-jubelio/'
  },
  [CHANNELS.ZILINGO]: {
    channelName: 'Zilingo',
    channelId: CHANNELS.ZILINGO,
    channelLogo: ZilingoFullIcon,
    eduLink: '#' // not available
  },
  [CHANNELS.FACEBOOK]: {
    channelName: 'Facebook',
    channelId: CHANNELS.FACEBOOK,
    channelLogo: FacebookFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/menu-integrasi/integrasi-channel-penjualan-ke-jubelio/integrasi-sosial-media-facebook-dan-ig-ke-jubelio/cara-integrasi-sosial-media-ke-jubelio/' 
  },
  [CHANNELS.WOOCOMMERCE]: {
    channelName: 'Woocommerce',
    channelId: CHANNELS.WOOCOMMERCE,
    channelLogo: WoocommerceFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/menu-integrasi/integrasi-channel-penjualan-ke-jubelio/cara-integrasi-woocommerce-webstore-ke-jubelio/' 
  },
  [CHANNELS.DEALPOS]: {
    channelName: 'Dealpos',
    channelId: CHANNELS.DEALPOS,
    channelLogo: DealPosFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/menu-integrasi/integrasi-channel-penjualan-ke-jubelio/cara-integrasi-deal-pos-ke-jubelio/' 
  },
  [CHANNELS.JUBELIO_POS]: {
    channelName: 'Jubelio POS',
    channelId: CHANNELS.JUBELIO_POS,
    channelLogo: JubelioPosFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/memulai-dengan-jubelio-pos/cara-integrasi-pos-ke-jubelio/' 
  },
  [CHANNELS.SHOPIFY]: {
    channelName: 'Shopify',
    channelId: CHANNELS.SHOPIFY,
    channelLogo: ShopifyFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/menu-integrasi/integrasi-channel-penjualan-ke-jubelio/cara-integrasi-shopify-ke-jubelio/' 
  },
  [CHANNELS.AKULAKU]: {
    channelName: 'Akulaku',
    channelId: CHANNELS.AKULAKU,
    channelLogo: AkulakuFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/menu-integrasi/integrasi-channel-penjualan-ke-jubelio/cara-integrasi-akulaku-ke-jubelio/' 
  },
  [CHANNELS.TIKTOK]: {
    channelName: 'Tiktok',
    channelId: CHANNELS.TIKTOK,
    channelLogo: TiktokFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/menu-integrasi/integrasi-channel-penjualan-ke-jubelio/cara-integrasi-tiktok-shop-ke-jubelio/' 
  },
  [CHANNELS.TADA]: {
    channelName: 'Tada',
    channelId: CHANNELS.TADA,
    channelLogo: TadaFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/menu-integrasi/integrasi-channel-penjualan-ke-jubelio/cara-integrasi-tada-ke-jubelio/' 
  },
  [CHANNELS.EVERMOS]: {
    channelName: 'Evermos',
    channelId: CHANNELS.EVERMOS,
    channelLogo: EvermosFullIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/menu-integrasi/integrasi-channel-penjualan-ke-jubelio/cara-integrasi-tada-ke-jubelio/' 
  },
  [CHANNELS.SHOPLINE]: {
    channelName: 'Shopline',
    channelId: CHANNELS.SHOPLINE,
    channelLogo: ShoplineIcon,
    eduLink: 'https://v2.edu.jubelio.com/documentation/menu-integrasi/integrasi-channel-penjualan-ke-jubelio/cara-integrasi-shopline-ke-jubelio/' 
  },
  [CHANNELS.PLUGO]: {
    channelName: 'Plugo',
    channelId: CHANNELS.PLUGO,
    channelLogo: PlugoFullIcon,
    eduLink: '#' 
  },
  [CHANNELS.INTERNAL]: {
    channelName: 'Internal Jubelio',
    channelId: CHANNELS.INTERNAL,
    channelLogo: JubelioIcon,
    eduLink: '#' 
  },
  [CHANNELS.PLUGO]: {
    channelName: 'Plugo',
    channelId: CHANNELS.PLUGO,
    channelLogo: PlugoIcon,
    eduLink: '#' 
  },
};

const SUPPORTED_IMPORT_FROM_MP = [
  CHANNELS.SHOPEE, CHANNELS.LAZADA, CHANNELS.TOKOPEDIA, CHANNELS.TIKTOK
]

const dateFormatRegex = /^\d{2} [A-Z][a-z]{2} \d{4}$/; // 'dd mmm yyyy' format regex
const timeFormatRegex = /^([01]\d|2[0-3]):[0-5]\d$/;

export { channelDetail, CHANNELS as channels, dateFormatRegex, timeFormatRegex, SUPPORTED_IMPORT_FROM_MP };
// export default channelLogo;
