import React from 'react';
import { DialogContentStyled, Label, LeftContentWrapper, RightContentWrapper } from 'components/Dialog/DialogContent';
import { LeftContent } from 'components/Dialog/DialogLeftContent';
import { Bottom, BottomActions, ConnectButton, RightForm } from 'components/Dialog/DialogRightContent';
import { ErrorMessage, Input, InputGrid } from 'components/Form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { plugoAuthorize, shopifyAuthorize } from 'api/webstore';
import { useAppStore } from 'stores/appStore';
import { DialogProps } from 'interfaces/Props';
import { showAlert } from 'components/Alert';
import { channels } from 'shared/constants';

import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import VerticalDividers from 'components/CustomDividers/VerticalDividers';
import WarningIcon from '@mui/icons-material/Warning';
import config from 'config';

interface FormValues {
  vendorId: string;
  apiKey: string;
}

const PlugoContent: React.FunctionComponent<DialogProps> = ({ handleClosePrimary, getListChannel }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();
  const { setShowConnectedPopup, disablePrevButton } = useAppStore();

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    try {
      const {
        data: { status: dataStatus, message }
      } = await plugoAuthorize(formData.vendorId, formData.apiKey);

      if (dataStatus === 'ok') {
        if (!disablePrevButton) {
          setShowConnectedPopup(true);
          handleClosePrimary();
          getListChannel();
        } else {
          location.replace(config.baseUrl);
        }
      } else {
        showAlert('error', `${message}`, 5000);
      }
    } catch (err) {
      const error = err as Error;
      console.error(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  return (
    <DialogContentStyled>
      <LeftContentWrapper>
        <LeftContent channelId={channels.PLUGO} />
      </LeftContentWrapper>
      <VerticalDividers />
      <RightContentWrapper>
        <RightForm id="plugo_form" onSubmit={handleSubmit(onSubmit)}>
          <InputGrid
            wide={true}
            label={<Label className="requiredField">Vendor ID</Label>}
            input={
              <Input
                isError={errors.vendorId?.message ? true : false}
                type={'text'}
                placeholder={'Vendor ID'}
                {...register('vendorId', {
                  required: { value: true, message: 'Vendor ID harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.vendorId && (
                <ErrorMessage icon={<WarningIcon fontSize="small" color="error" />} message={errors.vendorId.message} />
              )
            }
          />
          <InputGrid
            wide={true}
            label={<Label className="requiredField">API Key</Label>}
            input={
              <Input
                isError={errors.apiKey?.message ? true : false}
                type={'text'}
                placeholder={'API Key'}
                {...register('apiKey', {
                  required: { value: true, message: 'API Key harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.apiKey && (
                <ErrorMessage icon={<WarningIcon fontSize="small" color="error" />} message={errors.apiKey.message} />
              )
            }
          />
        </RightForm>
        <Bottom>
          <HorizontalDividers />
          <BottomActions>
            <ConnectButton form="plugo_form">Sambungkan</ConnectButton>
          </BottomActions>
        </Bottom>
      </RightContentWrapper>
    </DialogContentStyled>
  );
};

export default PlugoContent;
