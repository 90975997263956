import React, { useState, useEffect, useCallback } from 'react';
import { getChannelList } from 'api/integrationStep';
import { useAppStore } from 'stores/appStore';
import {
  BeginningContentList,
  ContentItem,
  DotIcon,
  DotInside,
  ImgWrap,
  LeftContent,
  StoreNameText
} from './StepThreeStyle';
import { Data } from '../StepTwo/Accordion';
import { channelDetail } from 'shared/constants';
import { ChannelDetail } from 'interfaces/Channels';
import { showAlert } from 'components/Alert';

const Beginning: React.FunctionComponent = () => {
  const [channelList, setChannelList] = useState([]);
  const { cantSkip, canSkip, masterShop, setMasterShop } = useAppStore();

  const getListChannel = useCallback(async () => {
    try {
      const { data: dataChannel, status, statusText } = await getChannelList();
      if (status === 200) {
        setChannelList(dataChannel);
      } else {
        alert(`Something went wrong ${statusText}`);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  }, [getChannelList]);

  const handleItemClicked = (item: Data) => {
    setMasterShop(item);
    if (masterShop !== null) {
      canSkip();
    }
  };

  const channels = channelDetail as ChannelDetail;

  useEffect(() => {
    getListChannel();
    const isMasterSelected = masterShop !== null;
    if (isMasterSelected) {
      canSkip();
    } else {
      cantSkip();
    }
  }, [masterShop]);

  return (
    <BeginningContentList>
      {channelList.map((el: Data, idx) => {
        if (el.store_id !== -2) {
          return (
            <ContentItem
              key={idx}
              onClick={() => handleItemClicked(el)}
              current={masterShop?.store_id.toString()}
              id={el.store_id.toString()}
            >
              <LeftContent>
                <DotIcon current={masterShop?.store_id.toString()} id={el.store_id.toString()}>
                  <DotInside current={masterShop?.store_id.toString()} id={el.store_id.toString()} />
                </DotIcon>
                <ImgWrap width="20px" height="20px">
                  <img src={channels[el.channel_id]?.channelLogo} alt={el.channel_name} />
                </ImgWrap>
                <StoreNameText font_size="12px">{el.store_name}</StoreNameText>
              </LeftContent>
              {el.channel_id === 2 && (
                <p style={{ fontSize: '13px', marginRight: '2rem' }}>
                  Pastikan stok produk tidak kosong agar bisa terdownload di Jubelio.
                </p>
              )}
            </ContentItem>
          );
        }
      })}
    </BeginningContentList>
  );
};

export default Beginning;
